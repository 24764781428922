import { useContext, useEffect, useState } from 'react';
import { Logout } from '../../../components/Logout';
import Select from 'react-select';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export function Header({
  userData,
  selectClubs,
  onChangeClub,
  club,
  options,
  Height,
  HeightUnit,
  Weight,
  WeightUnit,
  dominantHand,
  age,
  ...restProps
}) {
  const name =
    restProps?.role === 'CLUB' ? club?.ClubName : userData?.myProfile.FullName;
  const [profileImage, setProfileImage] = useState(
    userData?.myProfile.ImageProfile
      ? `${TheApp.Env.BaseUrl}${userData?.myProfile.ImageProfile}`
      : null
  );
  // check if there is at least one badge in header
  const [barIsVisible, setBarIsVisible] = useState(false);
  const [statusForm, setStatusForm] = useState(null);
  const appContext = useContext(AppContext);
  const [hasVisibleModal, setHasVisibleModal] = useState(appContext.hasVisibleModal);
  const stdCall = useStandardCall();
  let lng = window.location.pathname.split('/')[1] ?? 'it';

  const navigate = useNavigate();

  function UpdateProfileWithImg(e) {
    const selectedfile = e.target.files;
    if (selectedfile.length > 0) {
      const [imageFile] = selectedfile;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const srcData = fileReader.result;
        /* console.log('base64:', srcData); */
        setProfileImage(srcData);
        const rq = {
          myProfile: {
            ...TheApp.CurrentContext.userSession.userData.myProfile,
            ImageProfile: srcData
          }
        };
        let call = stdCall.post('app/updateprofile', rq, (rs) => {
          if (rs.success) {
            const sub = appContext.userSession.userData.myProfile.Subscriptions;
            const msg = appContext.userSession.userData.myProfile.MessagesCount;
            const newProfile = {
              ...appContext.userSession.userData.myProfile,
              ...rs.myProfile,
              MessagesCount: msg,
              Subscriptions: sub
            };
            appContext._setters._setProfile(newProfile);
            setStatusForm({
              status: true,
              success: true,
              message: 'Immagine profilo aggiornata correttamente'
            });
          } else {
            setStatusForm({
              status: true,
              success: false,
              message: rs?.Errors[0]?.Message || rs.Message
            });
          }
        });
      };
      fileReader.readAsDataURL(imageFile);
    }
  }
  useEffect(() => {
    if (statusForm && !statusForm?.success) {
      toast.error(statusForm?.message);
    }

  }, [statusForm]);
  useEffect(() => {
    if (Height || Weight || dominantHand || club.Coach?.FullName) {
      setBarIsVisible(true);
    } else {
      setBarIsVisible(false);
    }
  }, [Height, Weight, dominantHand, club.Coach?.FullName]);

  useEffect(() => {
    setHasVisibleModal(appContext.hasVisibleModal);
  }, [appContext.hasVisibleModal]);

  return (
    <div className={`header-wrap ${hasVisibleModal ? "has-visible-modal" : ""}`}>
      <header className="container header-container">
        <div className="row wrap-sm">
          <div className="col-2 d-flex align-items-center col-main-logo">
            <div className="wrapper-logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo-white.png`}
                alt="Hyper Tennis"
              />
            </div>
          </div>
          <div className="col-10 wrapper-user-info">
            <div className="row">
              <div
                className="col-3"
                style={{ position: 'relative' }}>
                <div className="wrapper-user-avatar">
                  {/* upload start */}
                  <div className="wrapper-upload-input-avatar">
                    <input
                      type="file"
                      accept="image/*"
                      className="localUploadProfileImage"
                      onChange={UpdateProfileWithImg}
                    />
                  </div>
                  {/* upload end */}
                  {profileImage ? (
                    <>
                      <div className="badge-edit-photo">
                        <i className="fa-solid fa-pencil"></i>
                      </div>
                      <img
                        /*  src={`${process.env.PUBLIC_URL}/assets/avatar.png`} */
                        src={`${profileImage}`}
                        alt="Avatar"
                      />
                    </>
                  ) : (
                    <div className="avatar-default">
                      <i className="fa-solid fa-camera"></i>
                      <div className="text-avatar-default">
                        Clicca per caricare la tua foto
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6 d-flex align-items-center">
                <div
                  className={`wrapper-user-name ${restProps?.role === 'CLUB' && 'full-width'
                    }`}>
                  {name}
                </div>
              </div>
              <div className="col-3 d-flex flex-direction-column justify-content-end">
                <div className='wrapper-suggestions'>
                  <div className='wrapper-suggestions-btn' onClick={() => navigate(`/${lng}/dashboard/suggestions`)}>
                    <i className="fa-solid fa-message"></i>
                  </div>
                </div>
                {restProps?.role !== 'CLUB' && (
                  <div className="wrapper-select-club">
                    <label id="select-club-label" htmlFor="select-club-input">
                      Seleziona la tua società
                    </label>
                    {selectClubs.map((input) => (
                      <Select
                        defaultValue={options[0]}
                        key={input.id}
                        inputId='select-club-input'
                        options={input.options}
                        onChange={onChangeClub}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderWidth: 2,
                            borderColor: '#fff',
                            padding: '0',
                            color: '#1976D2',
                            height: 'auto'
                          })
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 wrapper-user-info show-on-sm">
            <div className="row">
              <div
                className="col-sm-6 sm-6 xs-0 xs-ml-auto"
                style={{ position: 'relative' }}>
                <div className="wrapper-user-avatar">
                  {/* upload start */}
                  <div className="wrapper-upload-input-avatar">
                    <input
                      type="file"
                      accept="image/*"
                      className="localUploadProfileImage"
                      onChange={UpdateProfileWithImg}
                    />
                  </div>
                  {/* upload end */}
                  {profileImage ? (
                    <img
                      /*  src={`${process.env.PUBLIC_URL}/assets/avatar.png`} */
                      src={`${profileImage}`}
                      alt="Avatar"
                    />
                  ) : (
                    <div className="avatar-default">
                      <i className="fa-solid fa-camera"></i>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6 sm-6">
                <div
                  className={`wrapper-user-name ${restProps?.role === 'CLUB' && 'full-width'
                    }`}>
                  {name}
                </div>

                {restProps?.role !== 'CLUB' && (
                  <div className="wrapper-select-club mt-1">
                    {selectClubs.map((input) => (
                      <Select
                        defaultValue={options[0]}
                        key={input.id}
                        options={input.options}
                        onChange={onChangeClub}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderWidth: 0,
                            borderColor: '#fff',
                            padding: '0',
                            color: '#1976D2',
                            height: 'auto',
                            minHeight: 'auto'
                          })
                        }}
                      />
                    ))}
                  </div>
                )}

                <div className="wrapper-logout show-on-xs">
                  <Logout></Logout>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className={`container info-user ${barIsVisible === false ? 'hide-on-xs' : ''
          }`}>
        <div className="row info-user-container">
          <div className="col-2 sm-6 xs-0 xs-p-0"></div>
          <div className="col-10 sm-6 xs-0 xs-grow">
            <div className="row xs-reverse">
              <div className="col-3 hide-on-sm"></div>
              <div className="col-7 sm-8 xs-grow">
                <div className="d-flex single-infos">
                  {Height && (
                    <div className="single-info height">
                      {Height}
                      {HeightUnit}
                    </div>
                  )}
                  {Weight && (
                    <div className="single-info weight">
                      {Weight}
                      {WeightUnit}
                    </div>
                  )}
                  {dominantHand && (
                    <div className="single-info dominant-hand">
                      {dominantHand}
                    </div>
                  )}
                  {age > 0 && <div className="single-info age">{age} anni</div>}
                  {club.Coach?.FullName && (
                    <div className="single-info coach">
                      {club?.Coach?.FullName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-2 sm-4 xs-0 xs-auto xs-ml-auto hide-on-xs">
                <div className="wrapper-logout">
                  <Logout></Logout>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
