import { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import SingleInput from '../../../components/SingleInput';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { useForm } from 'react-hook-form';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
export function SuggestionsTab({setActiveTab}) {
  const navigate = useNavigate();
  let lng = window.location.pathname.split('/')[1] ?? 'it';
  const [currentLng, setCurrentLng] = useState(lng);
  const appContext = useContext(AppContext);
  const [statusForm, setStatusForm] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const stdCall = useStandardCall();
  function onSendMessage(data) {

    // date
    const rq = {
      Content: data.Content,
      Type: data.Type.value,
    };

    appContext._setters._setLoading(true);
    let call = stdCall.post('app/ht/report', rq, (rs) => {
      if (rs.Success) {
        setStatusForm({
          status: true,
          success: true,
          message: 'Report inviato correttamente'
        });
        appContext._setters._setLoading(false);
        reset();
      } else {
        setStatusForm({
          status: true,
          success: false,
          message: rs.Message
        });
        appContext._setters._setLoading(false);
      }
    });
  }


  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        const toasts = toast.success(statusForm?.message);
      }
      if (!statusForm?.success) {
        const toasts = toast.error(statusForm?.message);
      }
    }
  }, [statusForm]);

  const inputs = [
    {
      id: 'Type',
      label: 'Oggetto',
      type: 'select',
      isMulti: false,
      placeholder: `Seleziona la tipologia`,
      layout: 'col-lg-12 col-md-6',
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      options:
      {
        Items: [
          { Value: 0, Label: 'Suggerimento' },
          { Value: 1, Label: 'Segnalazione' },
        ]
      }
    },
    {
      id: 'Content',
      label: 'Messaggio',
      type: 'textarea',
      placeholder: 'Insersci il contenuto del report da inviare',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-12 col-md-12'
    }
  ];

  return (
    <div className="wrapper-sender-message">
    <div className="wrapper-sender-message__header">
      <div className="wrapper-sender-message__header__title">
        <h3>Inviaci un suggerimento o una segnalazione</h3>
      </div>
      <div
          className="btn-close"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("test");
            navigate(`/${currentLng}/dashboard`);
          }}>
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
    </div>
    <div className="wrapper-form">
      <form onSubmit={handleSubmit(onSendMessage)}>
        <div className="row">
          {inputs.map((input) => (
            <SingleInput
              key={input.id}
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              {...input}
            />
          ))}
        </div>
        <input
          value={'invia report'}
          type="submit"
          className="btn primary"
        />
      </form>
      <div
        className={`wrapper-message to-appear ${
          statusForm?.status
            ? statusForm?.success
              ? 'alert-success'
              : 'alert-danger'
            : ''
        } ${statusForm?.status ? 'visible' : ''}`}>
        {statusForm?.status && <p>{statusForm?.message}</p>}
      </div>
    </div>
  </div>
  );
}
